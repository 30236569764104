@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700&display=swap');

html {
  box-sizing: border-box;
  height: 100%;
}

*,
*::before,
*::after {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
font,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-weight: inherit;
  font-style: inherit;
  font-size: 100%;
  font-family: inherit;
  vertical-align: baseline;
}

img {
  max-width: 100%;
  display: block;
}

a {
  text-decoration: none;
  color: inherit;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

i {
  margin: 0;
  padding: 0;
}

p {
  margin: 0;
  padding: 0;
}

body {
  height: 100%;
  display: flex;
  flex-direction: column;
}

main {
  flex: 1 0 auto;
}

footer {
  flex-shrink: 0;
}

.header-logo {
  width: 222px;
  height: 77px;
}

.header-menu {
  display: flex;
  align-items: center;
}

.header-menu__active {
  border-bottom: 3px solid #98ca48;
}

.header-wrapper {
  display: flex;
  justify-content: space-between;
  padding: 10px 30px;
  position: absolute;
  width: 100%;
  max-width: 100%;
  top: 3%;

  z-index: 3;
}

.header-menus {
  display: flex;
  align-items: center;
}

.header-menu {
  display: flex;
  align-items: center;
}

.header-menu li a {
  padding-left: 20px;
  padding-right: 20px;
  color: #fff;
  padding-bottom: 5px;
  transition: 0.3s all;
  padding-bottom: 10px;
}

.header-menu li a:hover {
  border-bottom: 3px solid #fff;
  transition: 0.3s all;
}

.header1 {
  background-color: #28282a !important;
  position: fixed;
  z-index: 4;
  width: 100%;
  max-width: 100%;
}

.header1 .header-wrapper {
  top: 0;
  background-color: #28282a;
}

.header-language {
  color: #fff;
  position: relative;
  width: 70px;
  margin-left: 50px;
}

.header-language__current {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  color: #fff;
}

.header-language__current:hover {
  color: #bbb;
} 

.header-language__menus {
  position: absolute;
  width: 100%;
  margin-top: 10px;
  background-color: #28282a;
}

.header-language__menu {
  border-bottom: 1px solid #fff;
  padding: 10px;
  cursor: pointer;
  background-color: #28282a;
}

.header-language__menu:hover {
  background-color: #434344;
}

.header-language__menu-icon {
  transform: rotate(180deg);
  transition: all 0.3s;
}

.header-language__menu:last-child {
  margin-bottom: 0px;
  border-bottom: none;
}

.mb-20 {
  margin-bottom: 20px;
}

.title {
  font-family: "Montserrat";
  font-weight: 700;
  font-size: 18px;
  color: #98ca48;
  position: relative;
  text-align: center;
}

.onas {
  padding: 100px 0;
}

.onas-items {
  display: -ms-grid;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 50px;
}

.onas-item1 {
  display: flex;
  justify-content: flex-end;
}

.onas-head {
  text-transform: uppercase;
  font-weight: 600;
  font-size: 18px;
  color: #98ca48;
  position: relative;
}

.onas-title {
  font-size: 28px;
  font-weight: 700;
  line-height: 37px;
  padding-right: 100px;
  margin-top: 20px;
  margin-bottom: 30px;
}

.onas-text {
  font-size: 17px;
  color: #6d6d6d;
  font-weight: 500;
  line-height: 29px;
}

.onas-lists {
  margin: 30px 0;
  margin-bottom: 40px;
}

.onas-list {
  display: flex;
/*  margin-bottom: 10px; */
  font-size: 16px;
  line-height: 24px;
  color: #6d6d6d;
  font-weight: 500;
}

.onas-list span {
  margin-right: 10px;
}

.onas-photo {
  border: 6px solid #98ca48;
  border-left: none;
  border-bottom: none;
  width: 85%;
  height: 550px;
  position: relative;
}

.onas-photo__img1 {
  position: absolute;
  top: -30px;
  left: -30px;
  width: 80%;
  height: 60%;
  -o-object-fit: cover;
     object-fit: cover;
}

.onas-photo__img2 {
  position: absolute;
  bottom: -10px;
  left: -40px;
  width: 55%;
  height: 45%;
  -o-object-fit: cover;
     object-fit: cover;
}

.onas-photo__img3 {
  position: absolute;
  bottom: -10px;
  right: -20px;
  width: 55%;
  height: 60%;
  -o-object-fit: cover;
     object-fit: cover;
}

.text {
  font-size: 16px;
  color: #6d6d6d;
  font-weight: 500;
  line-height: 26px;
}

.btn {
  background-color: #98ca48;
  display: inline;
  padding: 12px 25px;
  border-radius: 5px;
  font-size: 18px;
  font-weight: 600;
  color: #fff;
  cursor: pointer;
  transition: 0.3s all;
}

.btn:hover {
  background-color: #8cc138;
  transition: 0.3s all;
}

.directions {
  padding: 100px 0;
  position: relative;
  overflow: hidden;
  z-index: 1;
}

.directions-photo {
  position: absolute;
  top: 0;
  right: 0%;
  transform: translate(0%, 0%);
  height: 100%;
  margin-right: -400px;
}

.directions-title {
  font-weight: 700;
  font-size: 25px;
  color: #98ca48;
  position: relative;
  text-align: center;
  text-transform: uppercase;
}

.directions-title::after {
  content: "";
  width: 100px;
  height: 4px;
  position: absolute;
  display: block;
  bottom: -50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #ff7f02;
}

.directions-text {
  margin-top: 40px;
}

.directions-text p {
  text-align: center;
  margin-bottom: 10px;
  font-size: 20px;
  font-weight: 500;
}

.directions-items {
  display: -ms-grid;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-gap: 15px;
  margin-top: 40px;
}
/*
.directions-items {
  grid-template-columns: repeat(6, 1fr);
}*/
.directions-item {
  grid-column-end: span 2;
}
.directions-item:nth-last-child(-n+2) {
  grid-column-start: 2
}
.directions-item:nth-last-child(-n+1) {
  grid-column-start: 4
}

.directions-item {
  border: 1px solid #f3f1ff;
  border-radius: 5px;
  padding: 20px 25px;
  background: #fff;
  transition: 0.3s;
  cursor: pointer;
}

.directions-item:hover {
  box-shadow: 0 10px 15px rgba(25, 25, 25, 0.1);
  transition: 0.5s !important;
  border-bottom: 5px solid #FF930D !important;
}

.directions-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.directions-icon {
  width: 75px;
  max-width: 75px;
  height: 75px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

.directions-icon__color1 {
  background-color: #98ca48;
}

.directions-icon__color2 {
  background-color: #4881d6;
}

.directions-icon__color3 {
  background-color: #ca48c3;
}

.directions-icon__color4 {
  background-color: #ff930d;
}

.directions-icon__color5 {
  background-color: #d65148;
}

.directions-bg {
  width: 60px;
  max-width: 60px;
  height: 60px;
}

.directions-bg img {
  width: 100%;
  max-width: 100%;
  height: 100%;
}

.directions-content {
  margin-top: 40px;
}

.directions-content__title {
  font-weight: 700;
  font-size: 18px;
  color: #98ca48;
  text-align: left;
  margin-bottom: 20px;
}

.directions-content__text {
  font-weight: 500;
  font-size: 16px;
  text-align: left;
  line-height: 24px;
}

.directions-divider {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 0%);
}

.partners {
  padding: 70px 0;
  background-color: #ff7f021a;
}

.partners-title {
  font-family: "Montserrat";
  text-transform: uppercase;
  font-weight: 600;
  font-size: 25px;
  color: #98ca48;
  position: relative;
  padding-left: 20px;
}

.partners-title::after {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  transform: translate(0, -50%);
  width: 6px;
  height: 50px;
  background-color: #98ca48;
}

.partners-items {
  display: -ms-grid;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  margin-top: 40px;
  width: 90%;
  max-width: 90%;
  margin-left: auto;
  margin-right: auto;
}

.partners-item {
  display: flex;
  align-items: center;
  justify-content: center;
}
.partners-item:nth-child(3n)>.partners-item__img {
  width: 100%;
  max-width: 100%;
}

.partners-item__img {
  width: 50%;
  max-width: 50%;
}

.footer {
  background-color: #28282a;
  color: #fff;
}

.footer-logo {
  width: 222px;
  height: 70px;
}

.footer-logo__img {
  width: 100%;
  height: 100%;
}

.footer-items {
  display: -ms-grid;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  padding: 30px 0;
}

.footer-bottom {
  border-top: 1px solid #fff;
  padding: 20px 0;
  display: flex;
  justify-content: space-between;
}

.footer-bottom__text {
  text-align: right;
  color: #fff;
  font-size: 16px;
  line-height: 26px;
  width: 50%;
  max-width: 50%;
}

.footer-title {
  font-size: 22px;
  font-weight: 500;
}

.footer-text {
  margin-top: 30px;
  font-size: 16px;
  line-height: 25px;
}

.footer-contacts {
  margin-top: 20px;
}

.footer-contact {
  font-size: 16px;
  margin-bottom: 10px;
  line-height: 25px;
  display: flex;
  align-items: flex-start;
}

.footer-icon {
  margin-right: 10px;
  margin-top: 5px;
}

.footer-menus {
  display: flex;

  width: 50%;
}

.footer-menu {
  font-size: 16px;
  line-height: 25px;
  margin: 0 10px;
}

.insert {
  position: relative;
  width: 100%;
  max-width: 100%;
  height: 100vh;
 /* z-index: -1; */
}

.insert-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  max-width: 100%;
  height: 100%;
  max-height: 100%;
 /* z-index: -2; */
  transition: transform 0.2s ease;
}

.insert-bg__img {
  width: 100%;
  max-width: 100%;
  height: 100%;
}

.insert-bg__color {
  position: absolute;
  top: 0;
  left: 0;
  background-color: #151e4091;
  opacity: 0.7;
  width: 100%;
  height: 100%;
}

.insert-content {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -40%);
  text-align: center;
  color: #fff;
}

.insert-content h1 {
  text-transform: uppercase;
  font-size: 60px;
  font-family: "Montserrat";
  font-weight: 700;
}

.insert-content__text {
  font-family: "Montserrat";
  font-size: 22px;
  margin-top: 20px;
  line-height: 30px;
}

.insert-bottom {
  position: absolute;
  bottom: 3%;
  width: 100%;
}

.insert-items {
  display: flex;
  justify-content: space-between;
}

.insert-item {
  color: #fff;
  width: 25%;
  max-width: 25%;
  text-align: center;
  border-right: 1px solid #fff;
}

.insert-item:last-child {
  border-right: none;
}

.insert-item__sup {
  font-size: 45px;
  color: #98ca48;
  font-family: "Montserrat";
  font-weight: 700;
}

.insert-item__sub {
  font-size: 18px;
  margin-top: 10px;
}

.technology {
  background-color: #ff7f021a;
  padding: 100px 0;
}

.technology-title {
  font-family: "Montserrat";
  text-transform: uppercase;
  font-weight: 600;
  font-size: 25px;
  color: #98ca48;
  position: relative;
  padding-left: 20px;
}

.technology-title::after {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  transform: translate(0, -50%);
  width: 6px;
  height: 50px;
  background-color: #98ca48;
}

.technology-items {
  display: -ms-grid;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  margin-top: 50px;
}

.technology-item {
  border-left: 1px solid #98ca4842;
  border-top: 1px solid #98ca4842;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px 0;
  cursor: pointer;
}

.technology-item__top {
  border-top: none;
}

.technology-item__left {
  border-left: none;
}

.technology-item__img {
  width: 55%;
  max-width: 55%;
  filter: grayscale(1);
  opacity: 0.5;
  transition: all 0.35s ease;
  cursor: pointer;
}

.technology-item:hover .technology-item__img {
  filter: grayscale(0);
  opacity: 1;
  transition: all 0.35s ease;
}

.slick-list {
  overflow: hidden;
}

.slick-slide {
  width: 100%;
  max-width: 100%;
}

.slick-track {
  display: flex;
}

.draft {
  padding: 100px 0;
  position: relative;
}

.draft-photo__left {
  position: absolute;
  left: 0;
  top: -50%;
}

.draft-title {
  text-transform: uppercase;
  font-weight: 600;
  font-size: 25px;
  color: #98ca48;
  position: relative;
  padding-left: 20px;
}

.draft-title::after {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  transform: translate(0, -50%);
  width: 6px;
  height: 50px;
  background-color: #98ca48;
}

.draft-items {
  margin-top: 50px;
  position: relative;
}

.draft-items .slick-next .slick-arrow {
  position: absolute;
}

.draft-items .slick-slide {
  margin: 0 12px;
}

.draft-items .slick-list {
  margin: 0 -12px;
  overflow: static !important;
}

.draft-items .draft-prev {
  right: 0;
  z-index: 2;
  width: 50px;
  max-width: 50px;
  height: 50px;
  border: 2px solid #98ca48;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 2px;
}

.draft-items .draft-next {
  right: 80px;
  z-index: 2;
  width: 50px;
  max-width: 50px;
  height: 50px;
  border: 2px solid #98ca48;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 2px;
}

.draft-item {
  background-color: #f4f4f5;
  padding: 40px;
  border-radius: 10px;
}

.draft-item__title {
  font-size: 24px;
  font-weight: 700;
  text-indent: 30px;
  color: #443f3f;
}

.draft-item__text {
  text-indent: 30px;
  margin-top: 30px;
  margin-bottom: 40px;
  font-size: 18px;
  line-height: 26px;
}

.draft-item__title1 {
  font-size: 22px;
  font-weight: 600;
}

.draft-list {
  text-indent: 30px;
  font-size: 16px;
  margin-top: 10px;
  line-height: 24px;
}

.draft-bottom {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 500px;
  grid-template-columns: 1fr 500px;
}

.draft-btns {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

.draft-btn {
  background-color: #ff930d;
  font-size: 18px;
  font-weight: 500;
  color: #fff;
  padding: 12px 25px;
  cursor: pointer;
  border-radius: 5px;
}

.draft-btn:hover {
  background-color: #f38600;
}

.contacts {
  margin-top: 90px;
  padding: 100px 0;
  padding-bottom: 200px;
}

.contacts-title {
  width: 1000px;
  max-width: 100%;
  margin: auto;
  font-family: "Montserrat";
  text-transform: uppercase;
  font-weight: 600;
  font-size: 35px;
  color: #98ca48;
  position: relative;
  padding-left: 20px;
}

.contacts-title::after {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  transform: translate(0, -50%);
  width: 6px;
  height: 50px;
  background-color: #98ca48;
}

.contacts-items {
  width: 1000px;
  max-width: 100%;
  margin: auto;
  display: -ms-grid;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  margin-top: 70px;
}

.contacts-block {
  margin-bottom: 30px;
}

.contacts-block:last-child {
  margin-bottom: 0px;
}

.contacts-block__title {
  font-size: 24px;
  font-weight: 600;
}

.contacts-block__text {
  font-size: 18px;
  line-height: 28px;
  margin-top: 10px;
}

#map {
  width: 100%;
  height: 400px;
}

.about {
  margin-top: 90px;
}

.about-title {
  font-family: "Montserrat";
  text-transform: uppercase;
  font-weight: 600;
  font-size: 25px;
  color: #98ca48;
  position: relative;
  padding-left: 20px;
}

.about-title::after {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  transform: translate(0, -50%);
  width: 6px;
  height: 50px;
  background-color: #98ca48;
}

.about-text {
  font-size: 18px;
  line-height: 28px;
  text-indent: 30px;
  margin-top: 50px;
}

.my {
  padding: 100px 0;
}

.sector {
  padding: 100px 0;
  padding-bottom: 50px;
  background-color: #ff7f021a;
}

.sector-title {
  font-weight: 700;
  font-size: 25px;
  color: #98ca48;
  position: relative;
  text-align: center;
  text-transform: uppercase;
}

.sector-title::after {
  content: "";
  width: 50px;
  height: 4px;
  position: absolute;
  display: block;
  bottom: -50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #ff7f02;
}

.sector-text {
  font-size: 22px;
  font-weight: 700;
  line-height: 33px;
  margin-top: 50px;
  text-indent: 30px;
}

.sector-items {
  display: -ms-grid;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  margin-top: 50px;
}

.sector-item {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 50px;
}

.sector-item__text {
  text-align: center;
  margin-top: 30px;
  color: #959dac;
  font-size: 18px;
  font-weight: 600;
}

.team {
  padding: 100px 0;
  overflow: hidden;
}

.team-items {
  display: -ms-grid;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  margin-top: 50px;
}

.team-item {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.team-item__text {
  margin-top: 20px;
}

.team-photos {
  width: 80%;
  max-width: 80%;
  margin: auto;
  height: 500px;
  position: relative;
  margin-top: 50px;
}

.team-photos .team-next {
  position: absolute;
  left: -60px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}

.team-photos .team-prev {
  position: absolute;
  right: -60px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 2;
  cursor: pointer;
}

.team-photo {
  width: 100%;
  overflow: hidden;
  height: 500px !important;
}

.team-photo .slick-slide {
  height: 500px !important;
}

.team-photo__img {
  width: 100%;
  height: 100%;
}

.pt-50 {
  padding-top: 50px;
}

.projects {
  margin-top: 90px;
  padding-top: 100px;
  padding-bottom: 100px;
}

.projects-wrapper {
  position: relative;
}

.projects-title {
  font-family: "Montserrat";
  text-transform: uppercase;
  font-weight: 600;
  font-size: 35px;
  color: #98ca48;
  position: relative;
  padding-left: 20px;
}

.projects-title::after {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  transform: translate(0, -50%);
  width: 6px;
  height: 50px;
  background-color: #98ca48;
}

.projects-items {
  margin-top: 50px;
}

.projects-item {
  padding: 40px;
  padding-bottom: 60px;
  position: relative;
}

.projects-item__title {
  font-size: 24px;
  font-weight: 600;
  text-indent: 30px;
  margin-bottom: 30px;
}

.projects-item__text {
  text-indent: 30px;
  margin-bottom: 40px;
  font-size: 18px;
  line-height: 28px;
}

.projects-item__title1 {
  font-size: 22px;
  font-weight: 600;
}

.projects-lists {
  margin-bottom: 20px;
}

.projects-list {
  text-indent: 30px;
  margin-top: 10px;
  font-size: 16px;
  line-height: 26px;
}

.projects-btn {
  background-color: #ff930d;
  font-size: 18px;
  font-weight: 500;
  color: #fff;
  padding: 12px 25px;
  cursor: pointer;
  border-radius: 5px;
  display: inline-block;
  margin-top: 30px;
}

.projects-btn:hover {
  background-color: #f38600;
}

.projects-line {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 0%);
}

.projects-cubic__left {
  position: absolute;
  top: 0;
  left: 0;
}

.projects-cubic__right {
  position: absolute;
  top: 0;
  right: 0;
}

body {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
}

.container {
  width: 100%;
  max-width: 100%;
  margin: auto;
}

.phone-menu {
  display: none;
}

@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}

@media (min-width: 1300px) {
  .container {
    max-width: 1200px;
  }
}

@media (max-width: 1400px) {
  .directions-items {
    grid-template-columns: repeat(6, 1fr);
  }
  .directions-item {
    grid-column-end: span 2;
  }
  .directions-item:nth-last-child(-n+2) {
    grid-column-start: 2
  }
  .directions-item:nth-last-child(-n+1) {
    grid-column-start: 4
  }
}

@media (max-width: 1200px) {
  .container {
    max-width: 1060px;
    padding-left: 15px;
    padding-right: 15px;
  }

  .onas-items {
    grid-gap: 20px;
  }

  .onas-photo {
    width: 90%;
    height: 500px;
  }

  .onas-photo__img3 {
    right: -12px;
  }

  .directions-item {
    padding: 15px;
  }

  .directions-icon {
    width: 65px;
    max-width: 65px;
    height: 65px;
  }

  .directions-bg {
    width: 50px;
    max-width: 50px;
    height: 50px;
  }
}


.project-list__title {
  font-family: "Montserrat";
  text-transform: uppercase;
  font-weight: 600;
  font-size: 20px;
  color: #98ca48;
  position: relative;
  padding-left: 20px;
}

.project-list__title::after {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  transform: translate(0, -50%);
  width: 5px;
  height: 50px;
  background-color: #98ca48;
}
.project-item {
  padding-bottom: 30px;
  position: relative;
}
.project-item__title {
  font-size: 22px;
  font-weight: 600;
  text-indent: 30px;
  margin-bottom: 30px;
  width: 80%;
  max-width: 80%;
  margin: auto;
  text-align: center;
}

.projects1-block1_wrappes {
  display: grid;
  grid-template-columns: 1.2fr 0.8fr;
  margin-top: 50px;
  grid-gap: 20px;
}

.projects1-photo__img {
  width: 100%;
  max-width: 100%;
}

.projects1-list {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}

.projects1-list__text {
  margin-left: 10px;
}

.projects1-block2_wrappers {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  margin-top: 40px;
  margin-bottom: 40px;
}

.projects1-block2__title {
  font-size: 20px;
  font-weight: 600;
  text-indent: 20px;
}

.projects1-block2__lists {
  margin-top: 10px;
}

.projects1-block2__list {
  margin-bottom: 10px;
  font-size: 18px;
  font-weight: 500;
}

.projects1-block2_wrapper-text {
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  margin-top: 10px;
}

.projects1-block2_wrapper-photo {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.projects2-items_left {
  display: grid;
  grid-template-columns: 1fr 300px;
  margin-top: 20px;
  margin-bottom: 40px;
  grid-gap: 30px;
}

.projects2-items_right {
  display: grid;
  grid-template-columns: auto 1fr;
  margin-top: 20px;
  grid-gap: 30px;
}

.project4-chart {
  width: 100%;
  max-width: 100%;
  margin: 40px 0;
}
.project4-chart__img {
  width: 100%;
  max-width: 100%;
}

.projects4-block3__border {
  border: 5px solid #8cc138;
  padding: 40px;
}

.projects4-block3__items {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
}

.projects4-block3__item {
  display: flex;
  justify-content: center;
  align-items: center;
}

.projects4-block3__text {
  font-size: 18px;
  font-weight: 600;
  text-align: center;
  margin-bottom: 30px;
}

.projects4-block3__img {
  width: 40%;
  max-width: 40%;
}

.projects4-block3__grids {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}

.projects4-block3__grid {
  display: flex;
  justify-content: center;
  align-items: center;
}

.projects4-block3__photo {
  width: 40%;
  max-width: 40%;
}

.HeightIcon {
  margin: 30px 0;
  text-align: center;
}

.draft-item__none {
  display: none !important;
}

.draft-item__active {
  display: block !important;
}

.draft-head {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.draft-tag {
  display: flex; 
}

.draft-prev {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #98ca48;
  width: 40px;
  height: 40px;
  cursor: pointer;
}
.draft-next {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  border: 1px solid #98ca48;
  width: 40px;
  height: 40px;
  cursor: pointer;
}

.draft-next:hover {
  background-color: #98ca48;
  transition: all 0.2s;
}

.draft-next:hover>svg {
  fill: #ffffff;
  transition: all 0.2s;
}

.draft-prev:hover {
  background-color: #98ca48;
  transition: all 0.2s;
}

.draft-prev:hover>svg {
  fill: #ffffff;
  transition: all 0.2s;
}

.draft-tag__img {
  width: 80%;
  max-width: 80%;
}

.pt-20 {
  padding-top: 20px;
}

.projects2-items_photo {
  margin-top: 70px;
}

.projects3-items_photo {
  margin-top: 10px;
}

.portfolio-list {
  display: flex;
  align-items: flex-start;
  margin-bottom: 10px;
}

.portfolio-list span {
  margin-left: 10px;
  font-size: 17px;
  line-height: 27px;
}

.portfolio-list:first-child .mt-20{
  margin-top: 0;
}

.projects-lists {
  margin-left: 15px;
}

.sectors {
  background-color: #ff7f021a;
  padding-top: 60px;
  padding-bottom: 30px;
}

.header-language__active {
  background-color: #434344;
}

.team-photo__active {
  display: block;
}

.team-photo__none {
  display: none;
}

.team-photos__next {
  position: absolute;
  top: 50%;
  left: -40px;
  transform: translate(-0%, -50%);
}

.team-photos__prev {
  position: absolute;
  top: 50%;
  right: -40px;
  transform: translate(-0%, -50%);
}

.draft {
  position: relative;
}

.directions-photo__left {
  position: absolute;
  left: 0;
  top: 0;
}

.technology {
  position: relative;
}

@media (max-width: 992px) {
  .header-menu li a {
    padding-left: 15px;
    padding-right: 15px;
  }

  .header-logo {
    width: 200px;
    max-width: 200px;
    height: auto;
  } 
  
  .header-language {
    margin-left: 20px;
  }

  .onas-items {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .onas-item1 {
    justify-content: center;
    margin-top: 50px;
  }

  .onas-photo__img3 {
    right: -47px;
  }

  .directions-items {
    grid-template-columns: repeat(2, 1fr);
  }
  .directions-item {
    grid-column-end: span 1;
  }
  .directions-item:nth-last-child(-n+2) {
    grid-column-start: 2;
  }

  .directions-item:nth-child(5) {
    -ms-grid-column-span: 2;
    grid-column: span 2;
    width: 50%;
    max-width: 50%;
    margin: auto;
  }

  .insert-content h1 {
    font-size: 45px;
  }

  .insert-content__text {
    font-size: 20px;
  }

  .insert-items {
    flex-wrap: wrap;
  }

  .insert-item {
    width: 50%;
    max-width: 50%;
    margin-top: 20px;
  }

  .insert-item:nth-child(2) {
    border-right: none;
  }

  .technology-item__img {
    width: 65%;
    max-width: 65%;
  }

  .draft-bottom {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .draft .draft-next {
    width: 40px;
    max-width: 40px;
    height: 40px;
    top: -85px;
    right: 55px;
  }

  .draft .draft-prev {
    top: -85px;
    width: 40px;
    max-width: 40px;
    height: 40px;
  }

  .contacts {
    padding-bottom: 100px;
  }

  .contacts-title {
    width: 90%;
    max-width: 90%;
    margin: auto;
  }

  .contacts-items {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
    margin-top: 50px;
  }

  .contacts-item {
    width: 90%;
    max-width: 90%;
    margin: auto;
  }

  .contacts-map {
    margin-top: 30px;
  }

  .my {
    padding: 80px 0;
  }

  .team-items {
    grid-template-columns: repeat(3, 1fr);
  }

  .team-item {
    margin-bottom: 30px;
  }

  .sector {
    padding: 80px 0;
  }

  .sector-text {
    font-size: 20px;
    line-height: 30px;
    margin-top: 40px;
  }

  .sector-item__bottom {
    margin-bottom: 0 !important;
  }

  .team {
    padding: 80px 0;
  }

  .pt-50 {
    padding-top: 30px;
  }

  .projects {
    padding: 80px 0;
  }

  .projects-title {
    font-size: 30px;
  }

  .projects-item {
    padding: 10px;
    padding-bottom: 30px;
  }

  .projects-item__title {
    font-size: 22px;
    line-height: 32px;
    margin-bottom: 20px;
  }

  .projects-item__title1 {
    font-size: 20px;
    line-height: 30px;
  }

  .projects-item__text {
    font-size: 16px;
    line-height: 26px;
    margin-bottom: 20px;
  }

  .projects-btn {
    margin-top: 10px;
    font-size: 16px;
  }

  .projects1-block1_wrappes {
    grid-template-columns: 0.8fr 1fr;
  }
}

@media (max-width: 768px) {
  .phone-menu {
    display: flex;
    align-items: center;
  }

  .header-menus {
    flex-direction: column;
  }

  .header-language {
    margin-left: 0;
    position: static;
    width: 100%;
  }

  .header-language__menus {
    margin-top: 0;
    display: flex;
  }

  .header-language__menu {
    border-bottom: none;
  } 

  .partners-items {
    grid-template-columns: repeat(3, 1fr);
    margin-top: 40px;
  }

  .partners-item {
    justify-content: center;
    padding: 20px 0;
  }

  .partners-item__img {
    width: 40%;
    max-width: 40%;
  }

  .partners-items {
    grid-template-columns: repeat(3, 1fr);
    margin-top: 40px;
  }

  .partners-item {
    justify-content: center;
    padding: 20px 0;
  }

  .partners-item__img {
    width: 40%;
    max-width: 40%;
  }

  .footer-items {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .footer-item {
    margin-bottom: 20px;
  }

  .footer-item:last-child {
    margin-bottom: 0;
  }

  .technology-item {
    padding: 20px 0;
  }

  .projects2-items_left {
    grid-template-columns: 1fr;
  }

  .projects2-items_right {
    grid-template-columns: 1fr;
    grid-gap: 20px;
  }

  .projects2-items_photo {
    grid-row: 2;
  }

  .projects2-items_right .projects2-items_photo {
    margin-top: 0;
  }

  .projects2-items_right .projects-lists {
    margin-bottom: 0;
  }
}

@media (max-width: 756px) {
  .onas-photo {
    width: 90%;
    height: 500px;
  }

  .onas-photo__img3 {
    right: -33px;
  }
  
  .projects1-block1_wrappes {
    grid-template-columns: 1fr;
  }

  .projects1-block2_wrappers {
    grid-template-columns: 1fr;
  }

  .projects1-block2_wrapper {
    margin-bottom: 20px;
  }

  .header-menus {
    position: absolute;
    width: 75%;
    right: 0;
    bottom: -140px;
    background: #28282a;
    z-index: 999;
    display: none;
  }

  .header-menu {
    display: block;
    width: 100%;
  }

  .header-menu li a {
    width: 100%;
    display: block;
    padding: 10px 20px;
  }

  .header-menu li:last-child  a{
    padding-bottom: 20px;
  }

  .projects2-items_photo {
    margin-top: 30px;
    margin-left: auto;
    margin-right: auto;
  }

  .projects3-items_photo {
    margin-left: auto;
    margin-right: auto;
  }

  .projects4-block3__border {
    padding: 15px;
  }

  .projects4-block3__img {
    width: 50%;
    max-width: 50%;
  }
}

@media (max-width: 576px) {
  .onas {
    padding: 80px 0;
  }

  .onas-title {
    font-size: 25px;
    line-height: 35px;
    padding-right: 0;
  }

  .directions {
    padding: 80px 0;
  }

  .directions-text p {
    font-size: 18px;
    text-indent: 30px;
    line-height: 28px;
    text-align: left;
  }

  .directions-items {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
    width: 90%;
    max-width: 90%;
    margin: auto;
    margin-top: 30px;
  }


  .directions-item:nth-last-child(-n+2) {
    grid-column-start: 1;
  }

  .directions-item {
    padding: 15px 20px;
  }

  .directions-item:nth-child(5) {
    -ms-grid-column-span: 1;
    grid-column: span 1;
    width: 100%;
    max-width: 100%;
  }

  .directions-content__title {
    font-size: 18px;
    color: #98ca48;
    margin-bottom: 15px;
  }

  .footer-bottom {
    flex-wrap: wrap;
    text-align: center;
  }

  .footer-menus {
    width: 100%;
    max-width: 100%;
    margin-bottom: 10px;
    justify-content: center;
  }

  .footer-bottom__text {
    width: 100%;
    max-width: 100%;
    text-align: center;
  }

  .insert-content {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }

  .insert-content h1 {
    font-size: 35px;
  }

  .insert-content__text {
    margin-top: 15px;
    font-size: 18px;
    line-height: 28px;
  }

  .draft {
    padding: 80px 0;
  }

  .draft-item__title {
    font-size: 20px;
    line-height: 30px;
  }

  .draft-item__title1 {
    font-size: 20px;
  }

  .draft-item__text {
    margin-bottom: 25px;
    font-size: 16px;
    line-height: 26px;
  }

  .draft-list {
    line-height: 26px;
  }

  .draft-btns {
    margin-top: 20px;
  }

  .technology {
    padding: 80px 0;
  }

  .technology-items {
    margin-top: 40px;
  }

  .technology-item {
    padding: 10px 0;
  }

  .technology-item__img {
    width: 75%;
    max-width: 75%;
  }

  .sector-items {
    grid-template-columns: repeat(2, 1fr);
  }

  .sector-item {
    margin-bottom: 20px;
  }

  .sector-item__bottom {
    margin-bottom: 20px !important;
  }

  .team-photos {
    width: 90%;
    max-width: 90%;
    height: 400px;
  }

  .team-photo {
    height: 400px !important;
  }

  .team .team-prev {
    right: -55px;
  }

  .team .team-prev img {
    width: 70% !important;
    max-width: 70% !important;
  }

  .team .team-next {
    left: -40px;
  }

  .team .team-next img {
    width: 70% !important;
    max-width: 70% !important;
  }

  .projects4-block3__items {
    grid-template-columns: repeat(2, 1fr);
  }

  .projects4-block3__item {
    height: 170px;
  }

  .projects4-block3__img {
    width: 40%;
    max-width: 40%;
  }
}

@media (max-width: 480px) {
  .team-photos {
    height: 400px;
  }

  #map {
    width: 100%;
    height: 300px;
  }

  .project-list__title {
    text-align: center;
    padding-left: 0;
    font-size: 18px;
    line-height: 28px;
  }

  .project-list__title::after {
    width: 0;
    height: 0;
    display: none;
  }

  .projects-lists {
    margin-left: 0px !important;
  }

  .header-menus {
    width: 100%;
    max-width: 100%;
    bottom: -125px;
  }

  .onas {
    padding: 60px 0;
  }

  .onas-title {
    font-size: 22px;
    line-height: 30px;
    margin-top: 10px;
    margin-bottom: 20px;
  }

  .onas-lists {
    margin: 25px 0;
    margin-bottom: 35px;
  }

  .onas-photo {
    height: 380px;
  }

  .onas-photo__img1 {
    left: -20px;
    width: 98%;
  }

  .onas-photo__img2 {
    bottom: -10px;
    left: -20px;
    height: 48%;
  }

  .onas-photo__img3 {
    width: 56%;
    height: 51%;
    right: -28px;
  }

  .btn {
    font-size: 16px;
  }

  .directions {
    padding: 60px 0;
  }

  .directions-title {
    font-size: 22px;
    padding-bottom: 5px;
  }

  .directions-title::after {
    bottom: -30%;
  }

  .partners {
    padding: 60px 0;
  }

  .partners-items {
    grid-template-columns: repeat(2, 1fr);
    margin-top: 20px;
  }

  .partners-title {
    font-size: 22px;
    padding-left: 15px;
  }

  .partners-title::after {
    width: 5px;
    height: 42px;
  }

  .partners-item {
    justify-content: center;
    padding: 20px 0;
  }

  .partners-item__img {
    width: 40%;
    max-width: 40%;
  }

  .projects1-block2__title {
    margin-bottom: 10px;
  }

  .projects1-block2__list {
    margin-bottom: 10px;
    font-size: 16px;
    font-weight: 500;
  }

  .footer-text {
    font-size: 14px;
    line-height: 23px;
  }

  .footer-contact {
    font-size: 14px;
    line-height: 23px;
  }

  .footer-menu {
    font-size: 14px;
    line-height: 23px;
  }

  .footer-bottom__text {
    font-size: 14px;
    line-height: 23px;
  }

  .footer-title {
    font-size: 20px;
  }

  .insert-bottom {
    background-color: #fff;
    width: 90%;
    max-width: 90%;
    bottom: 2%;
    left: 50%;
    transform: translateX(-50%);
    box-shadow: 1px 1px 8px 1px #171e379e;
    border-radius: 10px;
  }

  .insert-bottom .container {
    padding-left: 0;
    padding-right: 0;
  }

  .insert-item {
    color: #28282a;
    width: 50%;
    max-width: 50%;
    margin-top: 0;
    padding: 0;
    border-left: 1px solid #171e379e;
    border-top: 1px solid #171e379e;
    height: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .insert-item__sup {
    font-size: 35px;
  }

  .insert-item__sub {
    font-size: 16px;
  }

  .insert-content {
    top: 35%;
    left: 50%;
    transform: translate(-50%, -35%);
  }

  .technology {
    padding: 50px 0;
  }

  .technology-title {
    font-size: 22px;
    padding-left: 15px;
  }

  .technology-title::after {
    width: 5px;
    height: 42px;
  }

  .technology-items {
    grid-template-columns: repeat(4, 1fr);
  }
  .technology-item:last-child {
    display: none;
  }
  .technology-item:nth-last-child(-n+2) {
    display: none;
  }
  .technology-item {
    border: 0.5px solid #98ca4842;
  }
  .technology-item:nth-child(1) {
    border-left: none;
    border-top: none;
  }
  .technology-item:nth-child(2) {
    border-top: none;
  }
  .technology-item:nth-child(3) {
    border-top: none;
  }
  .technology-item:nth-child(4) {
    border-right: none;
    border-top: none;
  }
  .technology-item:nth-child(5) {
    border-left: none;
  }
  .technology-item:nth-child(8) {
    border-right: none;
  }
  .technology-item:nth-child(9) {
    border-left: none;
  }
  .technology-item:nth-child(12) {
    border-right: none;
  }
  .technology-item:nth-child(13) {
    border-left: none;
    border-bottom: none;
  }
  .technology-item:nth-child(14) {
    border-bottom: none;
  }
  .technology-item:nth-child(15) {
    border-bottom: none;
  }
  .technology-item:nth-child(16) {
    border-bottom: none;
    border-right: none;
  }

  .draft {
    padding: 60px 0;
  }

  .draft-title {
    font-size: 22px;
    padding-left: 15px;
  }

  .draft-title::after {
    width: 5px;
    height: 42px;
  }

  .draft .draft-next {
    width: 35px;
    max-width: 35px;
    height: 35px;
    top: -80px;
    right: 50px;
  }

  .draft .draft-prev {
    top: -80px;
    width: 35px;
    max-width: 35px;
    height: 35px;
  }

  .draft-item {
    padding: 20px;
  }

  .draft-item__title {
    font-size: 16px;
    line-height: 28px;
  }

  .draft-item__text {
    margin-top: 15px;
    margin-bottom: 15px;
  }

  .draft-btn {
    font-size: 16px;
  }

  .my {
    padding: 60px 0;
  }

  .projects {
    padding: 60px 0;
  }

  .contacts {
    padding: 60px 0;
  }

  .contacts-items {
    margin-top: 40px;
  }

  .contacts-title {
    font-size: 22px;
    padding-left: 15px;
  }

  .contacts-title::after {
    width: 5px;
    height: 40px;
  }

  .contacts-map {
    margin-top: 30px;
  }
  .contacts-block {
    margin-bottom: 20px;
  }
  .contacts-block__title {
    font-size: 20px;
  }

  .contacts-block__text {
    font-size: 16px;
    line-height: 26px;
    margin-top: 10px;
  }

  .about-title {
    font-size: 22px;
    padding-left: 15px;
  }

  .about-title::after {
    width: 5px;
    height: 42px;
  }

  .about-text {
    font-size: 16px;
    line-height: 26px;
    margin-top: 30px;
  }

  .sector {
    padding: 60px 0;
  }

  .sector-text {
    font-size: 18px;
    line-height: 30px;
    margin-top: 30px;
  }

  .sector-item__text {
    text-align: center;
    margin-top: 20px;
    color: #959dac;
    font-size: 16px;
    font-weight: 600;
  }

  .team {
    padding: 60px 0;
  }

  .team-items {
    grid-template-columns: repeat(2, 1fr);
  }

  .team-photos {
    margin-top: 30px;
    height: 350px;
  }

  .team-photos .team-photo {
    height: 350px !important;
  }

  .projects-title {
    font-size: 22px;
    padding-left: 15px;
  }

  .projects-title::after {
    width: 5px;
    height: 42px;
  }

  .projects-wrapper {
    padding-bottom: 20px;
  }

  .projects-item {
    padding-bottom: 40px;
  }

  .projects-item__title {
    font-size: 16px;
    line-height: 26px;
  }

  .portfolio-list span {
    font-size: 16px;
    line-height: 26px;
  }

  .mb-20 {
    margin-bottom: 10px;
  }

  .project-item__title {
    width: 100%;
    max-width: 100%;
    font-size: 18px;
    line-height: 28px;
  }
  
  .projects2-items_left {
    grid-template-columns: 1fr;
    grid-gap: 20px;
  }

  .projects2-items_left .projects-lists {
    margin-bottom: 0;
  }

  .projects2-items_left figure {
    margin: auto;
  }

  .projects2-items_right {
    grid-template-columns: 1fr;
    margin-top: 15px;
  }

  .projects4-block3__item {
    height: 150px;
  }

  .projects4-block3__img {
    width: 55%;
    max-width: 55%;
  }

  .projects4-block3__grids {
    grid-template-columns: 1fr;
  }

  .projects4-block3__grid {
    height: 150px;
  }

  .projects4-block3__photo {
    width: 30%;
    max-width: 30%;
  }
}

@media (max-width: 375px) {
  .team-photos {
    height: 300px;
  }

  .team-photos .team-photo {
    height: 300px !important;
  }
}

.header-menus__active {
  display: flex;
}

.portfolio5-photo {
  width: 80%;
  max-width: 80%;
  margin: auto;
}

.portfolio5-photo__img {
  width: 100%;
  max-width: 100%;
  height: 100%;
}

.draft-lists {
  margin-top: 10px;
}